import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import ComboButtonGroup, { ComboButtonId } from '../../ComboButtonGroup';
import { ApiClient } from '../../../services/ApiClient';
import {
  ExecutionForm,
  FormOfExecution,
  IndustryBranch,
  PermissionsEnum,
  ProductTypeEnum,
  TeachingForms,
  TypeOfMeasure,
  YesNoMapping,
} from '../../../utils/enum';
import { Editor } from '@tinymce/tinymce-react';
import { Certificate, Product } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { faDice, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { formatEuro, sortObjectKeys, normalizeString } from '../../../utils/utils';
import SelectWithSearch, { SelectOption } from '../../form/SelectWithSearch';
import NestableComponent from '../../NestableComponent';
import DeleteModal from '../../DeleteModal';
import useCompanyNavigate from '../../../utils/hooks/useCompanyNavigate';
import ArchiveModal from '../../ArchiveModal';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import Card from '../../bootstrap/card';
import GenericInfosCard from '../GenericInfosCard';
import { handleInputChange } from '../../../utils/form/utils';

type AddEditProductCertificationProps = {
  modalTitle: string;
  product?: Product;
  onSubmitSuccess: (productId: number) => void;
  onModalClose: () => void;
};

interface FormValues {
  title: string;
  name: string;
  type: string;
  degreeType: string;
  typeOfMeasure: string;
  industryBranch: string;
  calculatedCostRate: string;
  certShortDescription: string;
  lessonUnits: string;
  lessonUnitsDay: string;
  flexibleStart: boolean;
  formOfExecution: string;
  executionForm: string;
  teachingForm: string;
  numberOfParticipants: string;
  teachingThird: boolean;
  admissionThird: boolean;
  keywordTopic: string;
  keywordSubtopic: string;
  keywordArea: string;
  educationPlan: string;
  educationContent: string;
  measureCode: string
}

const AddEditProductCertificationModal: React.FC<
  AddEditProductCertificationProps
> = ({ modalTitle, product, onSubmitSuccess, onModalClose }) => {
  const { show, message, error, showToast, hideToast } = useToast();
  const [totalUes, setTotalUes] = useState(0);
  const [validated, setValidated] = useState(false);
  const { userHasPermissionByRight } = usePermissions();
  const [tabErrors, setTabErrors] = useState({
    educational_contents_modal: false,
    certification_modal: false,
    measures_concept_modal: false,
    certificate_modal: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const { productId } = useParams<{ productId?: string }>();
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>(
    'educational_contents_modal'
  );
  const [bdksCosts, setBdksCosts] = useState();
  const [certificateDetails, setCertificateDetails] = useState<Certificate>();
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const companyNavigate = useCompanyNavigate()
  const [formValues, setFormValues] = useState({
    title: product?.title ?? '',
    name: product?.name ?? '',
    teachingForm: product?.teachingForm ?? '',
    abschlussart: product?.abschlussart ?? '',
    actionPlan: product?.actionPlan ?? '',
    admissionThird: Boolean(product?.admissionThird) ?? false,
    bdks: product?.bdks,
    bdks_id: product?.bdks_id ?? null,
    systematics: product?.systematics,
    systematics_id: product?.systematics_id ?? '',
    calculatedCostRate: product?.calculatedCostRate ?? '',
    certShortDescription: product?.certShortDescription ?? '',
    costsPerParticipants: product?.costsPerParticipants ?? '',
    degreeExamType: product?.degreeExamType ?? '',
    degreeTitle: product?.degreeTitle ?? '',
    degreeType: product?.degreeType ?? '',
    descriptionLong: product?.descriptionLong ?? '',
    descriptionNewplan: product?.descriptionNewplan ?? '',
    educationContent: sortObjectKeys(product?.educationContent || []),
    educationContentGenerateNew: product?.educationContentGenerateNew ?? '',
    educationPdfFileUrl: product?.educationPdfFileUrl ?? '',
    educationPlan: normalizeString(product?.educationPlan || '') ?? '',
    educationType: product?.educationType ?? '',
    executionForm: product?.executionForm ?? '',
    fieldCheckCertificate: product?.fieldCheckCertificate ?? '',
    fieldCheckEducation: product?.fieldCheckEducation ?? '',
    fieldCheckNewplan: product?.fieldCheckNewplan ?? '',
    flexibleStart: Boolean(product?.flexibleStart) ?? false,
    formOfExecution: product?.formOfExecution ?? '',
    genPdf: product?.genPdf ?? '',
    industryBranch: product?.industryBranch ?? '',
    institution: product?.institution ?? '',
    keywords: product?.keywords ?? '',
    lessonUnits: product?.lessonUnits ?? '',
    lessonUnitsMonth: product?.lessonUnitsMonth ?? '',
    lessonUnitsWeek: product?.lessonUnitsWeek ?? '',
    lessonUnitsDay: product?.lessonUnitsDay ?? '',
    massnahmeArt: product?.massnahmeArt ?? '',
    mimeSourceAngebotUrl: product?.mimeSourceAngebotUrl ?? '',
    npDegreeType: product?.npDegreeType ?? '',
    npDescription: product?.npDescription ?? '',
    npTitle: product?.npTitle ?? '',
    numberOfParticipants: product?.numberOfParticipants ?? '',
    pidIdent: product?.pidIdent ?? '',
    requirements: product?.requirements ?? '',
    sortGroup: product?.sortGroup ?? '',
    status: product?.status ?? 2,
    statusCertification: product?.statusCertification ?? 0,
    statusKursnet: product?.statusKursnet ?? '',
    targetGroup: product?.targetGroup ?? '',
    teachingThird: Boolean(product?.teachingThird) ?? false,
    titleMassnahme: product?.titleMassnahme ?? '',
    type: product?.type ?? '',
    typeOfMeasure: product?.typeOfMeasure ?? '',
    ue: product?.ue ?? '',
    keywordTopic: '',
    keywordSubtopic: '',
    keywordArea: '',
    certificate_id: product?.certificate_id ?? null,
    certificate: product?.certificate,
    measureCode: product?.measureCode ?? ''
  });

  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const comboButtons = [
    {
      id: 'educational_contents_modal',
      label: 'Bildungsinhalte',
      error: tabErrors.educational_contents_modal,
    },
    {
      id: 'certification_modal',
      label: 'Zertifizierung',
      error: tabErrors.certification_modal,
    },
    {
      id: 'measures_concept_modal',
      label: 'Maßnahmenkonzept',
      error: tabErrors.measures_concept_modal,
    },
    {
      id: 'certificate_modal',
      label: 'Zertifikat',
      error: tabErrors.certificate_modal,
    },
  ];

  const validateTabFields = (tabId: string): boolean => {
    let hasError = false;

    switch (tabId) {
      case 'certification_modal':
        if (!formValues.degreeType?.toString().trim()) {
          hasError = true;
        }
        if (!formValues.industryBranch?.toString().trim()) {
          hasError = true;
        }
        if (!formValues.typeOfMeasure?.toString().trim()) {
          hasError = true;
        }
        if (!formValues.lessonUnits?.toString().trim()) {
          hasError = true;
        }
        if (!formValues.executionForm?.toString().trim()) {
          hasError = true;
        }
        if (!formValues.flexibleStart?.toString().trim()) {
          hasError = true;
        }
        if (!formValues.teachingForm?.toString().trim()) {
          hasError = true;
        }
        if (!formValues.formOfExecution?.toString().trim()) {
          hasError = true;
        }
        if (!formValues.numberOfParticipants?.toString().trim()) {
          hasError = true;
        }
        if (!formValues.teachingThird?.toString().trim()) {
          hasError = true;
        }
        if (!formValues.admissionThird?.toString().trim()) {
          hasError = true;
        }
        break;
      default:
        break;
    }

    setTabErrors((prev) => ({ ...prev, [tabId]: hasError }));
    return hasError;
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
      const allTabs = ['certification_modal'];

      allTabs.forEach((tabId) => {
        validateTabFields(tabId);
      });
    } else {
      setIsLoading(true);
      try {
        let response;
        if (productId) {
          response = await ApiClient.put(`/products/${productId}`, formValues);
        } else {
          response = await ApiClient.post('/products', formValues);
        }
        const productsId = response.data.id;
        onSubmitSuccess(productsId);
        setFormValues(response.data);
        setInitialFormValues(response.data);
        showToast('Erfolgreich gespeichert', false);
      } catch (error) {
        showToast('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        onModalClose();
        setValidated(false);
        setTabErrors({
          educational_contents_modal: false,
          certification_modal: false,
          measures_concept_modal: false,
          certificate_modal: false,
        });
      }
    }
  };

  const handleEducationContentChange = (value: string) => {
    setFormValues((prev: any) => ({ ...prev, educationContent: sortObjectKeys(value) }));
  };

  const handleSelectChange = async (
    id: string,
    selectedOption: { value: string; label: string } | null
  ) => {

    setFormValues((prev: any) => ({
      ...prev,
      [id]: selectedOption ? selectedOption.value : '',
    }));

    if (id === 'bdks_id') {
      const bdks = await ApiClient.get(`/bdks/${selectedOption?.value}`)
      setBdksCosts(bdks.data.costs)
    }
    if (id === 'certificate_id') {
      const certificate = await ApiClient.get(`/certificates/${selectedOption?.value}`)
      setCertificateDetails(certificate.data as Certificate)
    }
  };

  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false,
    placeholder = ''
  ) => {
    const commonProps = {
      value: type !== 'file' ? formValues[id] : '',
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
        handleInputChange(e, setFormValues),
      required: required,
      isInvalid: validated && !formValues[id],
    };

    return (
      <Form.Group className="mb-3 w-100" controlId={String(id)}>
        <Form.Label style={{ color: 'black' }}>{label}</Form.Label>
        {type === 'textarea' ? (
          <Form.Control
            as="textarea"
            {...commonProps}
            style={{
              backgroundColor: '#F9F9F9',
              color: 'black',
              height: '150px',
            }}
            placeholder={placeholder}
          />
        ) : (
          <Form.Control
            type={type}
            {...commonProps}
            style={{ backgroundColor: '#F9F9F9', color: 'black' }}
          />
        )}
        <Form.Control.Feedback type="invalid" />
      </Form.Group>
    );
  };

  const createSelectGroup = (
    id: keyof FormValues,
    label: string,
    options: SelectOption[],
    placeholder?: string,
    required = false,
    disabled: boolean = false
  ) => (
    <Form.Group
      style={{ color: 'black' }}
      className="mb-3 w-100"
      controlId={id}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        disabled={disabled}
        value={formValues[id]}
        onChange={(e) => handleInputChange(e, setFormValues)}
        required={required}
        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  const createEditorGroup = (
    id: keyof FormValues,
    label: string,
    initialValue: string
  ) => (
    <Form.Group className="mb-3 w-100" controlId={id}>
      <Form.Label style={{ color: 'black' }}>{label}</Form.Label>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        initialValue={initialValue}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help |' +
            'fontsize',
        }}
        onEditorChange={(content) => {
          setFormValues((prev: any) => ({
            ...prev,
            [id]: normalizeString(content),
          }));
        }}
      />
    </Form.Group>
  );

  const handleDeleteItem = (message: string, isError: boolean) => {
    showToast(message, isError)
    onModalClose()
    companyNavigate('/products')
  }

  return (
    <>
      <Modal
        centered
        show
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button"
                style={{ padding: 0 }}
              >
                <span className="visually-hidden">More options</span>
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>

                {product && product?.status === 3 && <DeleteModal
                  modalTitle="Auswahl löschen?"
                  buttonName="Löschen"
                  entityIds={product?.id}
                  entityType='products'
                  onSubmit={handleDeleteItem}
                  onDeleteModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'delete')}>
                </DeleteModal>}

                {product && product?.status !== 3 && <ArchiveModal
                  modalTitle="Auswahl archivieren?"
                  buttonName="Archivieren"
                  entityIds={product?.id}
                  entityType='products'
                  onSubmit={() => onSubmitSuccess(product?.id!)}
                  onArchiveModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'delete')}>
                </ArchiveModal>}

              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>

          <Form noValidate validated={validated} onSubmit={submit}>

            <Row>
              <Col lg={4}>
                <Card className='card-block card-stretch card-height'>
                  <Card.Body>
                    <h5>Allgemein</h5>
                    <div className="d-flex align-items-end">
                      {createFormGroup('title', 'Titel', 'text', true)}
                      {(product?.statusCertification === 0 || product?.statusCertification === 1) && <div>
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`coming-soon`}>
                              Funktion kommt noch
                            </Tooltip>
                          }
                        >
                          <span
                            className="d-inline-block"
                            tabIndex={0}
                            style={{ cursor: 'not-allowed', width: '35px' }}
                          >
                            <Button
                              variant="secondary"
                              className="btn-soft-primary round-button ms-2 mb-3"
                              style={{
                                padding: 0,
                                width: '35px',
                                height: '35px',
                              }}
                              disabled
                            >
                              <FontAwesomeIcon size="lg" icon={faDice} />
                            </Button>
                          </span>
                        </OverlayTrigger>
                      </div>}

                    </div>
                    {createSelectGroup(
                      'type',
                      'Produktart',
                      Object.entries(ProductTypeEnum).map(([value, label]) => ({
                        value,
                        label,
                      })),
                      'Auswählen...',
                      true
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col>
                    <Card>
                      <Card.Body>
                        <ComboButtonGroup
                          buttons={comboButtons}
                          selectedCombo={selectedCombo}
                          setSelectedCombo={setSelectedCombo}
                          borderRadius="normal"
                        ></ComboButtonGroup>

                        <div
                          style={{
                            display:
                              selectedCombo === 'educational_contents_modal'
                                ? 'block'
                                : 'none',
                          }}
                        >
                          <Row>
                            <Col lg={12}>
                              <Card className='card-block card-stretch card-height'>
                                <Card.Body>
                                  <div className="mx-5 py-5 rounded default-input-border">
                                    <NestableComponent
                                      educationContent={product?.educationContent || []}
                                      onTotalUesChange={setTotalUes}
                                      onItemsChange={handleEducationContentChange}
                                    ></NestableComponent>

                                  </div>

                                  <div className="mx-3 py-2 d-flex justify-content-end">      <h6>Gesamt: {totalUes} UE</h6></div>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </div>

                        <div
                          style={{
                            display:
                              selectedCombo === 'certification_modal' ? 'block' : 'none',
                          }}
                        >
                          <Row>
                            <Col lg={6}>
                              <Card className='card-block card-stretch card-height'>
                                <Card.Body>
                                  {createFormGroup('certShortDescription', 'Inhalte Kurzform', 'textarea')}
                                  {createFormGroup('degreeType', 'Abschluss', 'text', true)}
                                  {createSelectGroup(
                                    'industryBranch',
                                    'Wirtschaftszweig',
                                    Object.entries(IndustryBranch).map(([value, label]) => ({
                                      value,
                                      label,
                                    })),
                                    'Auswählen...',
                                    true
                                  )}
                                  {createSelectGroup(
                                    'typeOfMeasure',
                                    'Art der Maßnahme',
                                    Object.entries(TypeOfMeasure).map(([value, label]) => ({
                                      value,
                                      label,
                                    })),
                                    'Auswählen...',
                                    true
                                  )}
                                  {createFormGroup('measureCode', 'Maßnahmen-ID', 'text')}
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col lg={6}>
                              <Card className='card-block card-stretch card-height'>
                                <Card.Body>
                                  {createFormGroup(
                                    'lessonUnits',
                                    'Unterricht in UE',
                                    'number',
                                    true
                                  )}
                                  {createFormGroup(
                                    'lessonUnitsDay',
                                    'Unterricht in Tagen',
                                    'number',
                                    true
                                  )}
                                  {createSelectGroup(
                                    'executionForm',
                                    'Arbeitszeitmodell',
                                    Object.entries(ExecutionForm).map(([value, label]) => ({
                                      value,
                                      label,
                                    })),
                                    'Auswählen...',
                                    true
                                  )}
                                  {createSelectGroup(
                                    'flexibleStart',
                                    'Laufender Einstieg',
                                    Object.entries(YesNoMapping).map(([value, label]) => ({
                                      value,
                                      label,
                                    })),
                                    'Auswählen...',
                                    true
                                  )}

                                  {createSelectGroup(
                                    'teachingForm',
                                    'Unterrichtsform',
                                    Object.entries(TeachingForms).map(([value, label]) => ({
                                      value,
                                      label,
                                    })),
                                    'Auswählen...',
                                    true
                                  )}
                                  {createSelectGroup(
                                    'formOfExecution',
                                    'Durchführungsform (Maßnahme)',
                                    Object.entries(FormOfExecution).map(([value, label]) => ({
                                      value,
                                      label,
                                    })),
                                    'Auswählen...',
                                    true
                                  )}

                                  {createFormGroup(
                                    'numberOfParticipants',
                                    'Teilnehmeranzahl',
                                    'number',
                                    true
                                  )}
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <Card className='card-block card-stretch card-height'>
                                <Card.Body>
                                  <SelectWithSearch
                                    id="bdks_id"
                                    label="BDKS"
                                    placeholder="Suche..."
                                    apiEndpoint="/bdks"
                                    onChange={handleSelectChange}
                                    initialValue={formValues.bdks?.id ? { value: formValues.bdks.id, label: formValues.bdks.title } : null}
                                  />
                                  {(bdksCosts || formValues.bdks?.costs) && <p>Daraus ergibt sich ein B-DKS von {bdksCosts ? formatEuro(bdksCosts) : formValues.bdks?.costs}</p>}

                                  {createFormGroup('calculatedCostRate', 'Angepasster B-DKS')}
                                  <p>
                                    Der jeweilige B-DKS der Zertifizierungssystematik kann
                                    hiermit überschrieben werden.
                                  </p>
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col lg={6}>
                              <Card className='card-block card-stretch card-height'>
                                <Card.Body>
                                  {createSelectGroup(
                                    'teachingThird',
                                    'Einsatz von Dritten für Unterricht',
                                    Object.entries(YesNoMapping).map(([value, label]) => ({
                                      value,
                                      label,
                                    })),
                                    'Auswählen...',
                                    true
                                  )}
                                  {createSelectGroup(
                                    'admissionThird',
                                    'Zulassung/Berechtigungen Dritter erforderlich',
                                    Object.entries(YesNoMapping).map(([value, label]) => ({
                                      value,
                                      label,
                                    })),
                                    'Auswählen...',
                                    true
                                  )}
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </div>

                        <div
                          style={{
                            display:
                              selectedCombo === 'measures_concept_modal' ? 'block' : 'none',
                          }}
                        >
                          <Row>
                            <Col lg={12}>
                              <Card className='card-block card-stretch card-height'>
                                <Card.Body>
                                  <div className="d-flex justify-content-end">
                                    <Button
                                      variant="secondary"
                                      id="dropdown-basic"
                                      className="btn-soft-primary"
                                    >
                                      Vorlage wiederherstellen
                                    </Button>
                                  </div>

                                  {createEditorGroup(
                                    'educationPlan',
                                    '',
                                    product?.educationPlan! ?? ''
                                  )}
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </div>

                        <div
                          style={{
                            display:
                              selectedCombo === 'certificate_modal' ? 'block' : 'none',
                          }}
                        >
                          <Row>
                            <Col lg={6}>
                              <Card className='card-block card-stretch card-height'>
                                <Card.Body>
                                  <SelectWithSearch
                                    id="certificate_id"
                                    label="Zertifikat"
                                    placeholder="Suche..."
                                    apiEndpoint="/certificates"
                                    onChange={handleSelectChange}
                                    initialValue={formValues.certificate_id ? { value: formValues.certificate_id.toString(), label: formValues.certificate?.title! } : null}
                                  />
                                </Card.Body>
                              </Card>
                            </Col>
                            <Col lg={6}>
                              {certificateDetails &&
                                <GenericInfosCard
                                  title="Ausgewähltes Zertifikat:"
                                  infos={[
                                    {
                                      label: 'Titel',
                                      value: certificateDetails?.title || '-',
                                    },
                                    {
                                      label: 'Zertifikatsnummer',
                                      value: certificateDetails?.number || '-',
                                    },
                                    {
                                      label: 'Zertifizierer',
                                      value: certificateDetails?.certifier || '-',
                                    },
                                  ]}
                                />
                              }
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Button
              type="submit"
              style={{ display: 'none' }}
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditProductCertificationModal;
