import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GenericInfosCard from '../GenericInfosCard';
import SkeletonCard from '../../locations/skeleton/SkeletonCard';
import { Product } from '../../../interfaces';

interface CertificateDetailsProps {
  isLoading?: boolean;
  product?: Product
}

const CertificateDetails: React.FC<CertificateDetailsProps> = ({
  isLoading,
  product
}) => {
  function getCertificateInfo() {
    const companyId = sessionStorage.getItem('companyId') || 'oc';

    const certificateInfo = [
      {
        label: 'Titel',
        value: product?.certificate ? `<a style="color: black; text-decoration: underline" href="/${companyId}/certificates/${product?.certificate_id}">${product?.certificate?.title}</a>` : '-',
        isMultiline: true
      },
      {
        label: 'Zertifikatsnummer',
        value: product?.certificate?.number || '-',
      },
      {
        label: 'Zertifizierer',
        value: product?.certificate?.certifier || '-',
      },
    ]
    return certificateInfo;
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          {isLoading ? (
            <SkeletonCard sectionCount={2} />
          ) : (
            <GenericInfosCard
              title="Zertifikat"
              infos={getCertificateInfo()}
              firstColumnSize={4}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default CertificateDetails;
