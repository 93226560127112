import React, { memo, Fragment, useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/bootstrap/card';
import * as SettingSelector from '../../../store/setting/selectors';
import { useSelector } from 'react-redux';
import { login } from '../../../services/auth/AuthService';
import { Spinner } from 'react-bootstrap';

import { useToast } from '../../../services/context/ToastContext';
import NotificationToast from '../../../components/NotificationToast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase } from '@fortawesome/pro-solid-svg-icons';
import chapterNextLogo from '../../../assets/images/cn-logo-large.svg';
import oneCareerLogo from '../../../assets/images/oc-logo-large.svg';
import useCompanyNavigate from '../../../utils/hooks/useCompanyNavigate';

const SignIn = memo(() => {
  const  { companyId } = useParams<{ companyId?: string }>();
  const { show, message, error, showToast, hideToast } = useToast();
  const appName = useSelector(SettingSelector.app_name);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [companyLogo, setCompanyLogo] = useState('')
  let navigate = useNavigate();
  let companyNavigate = useCompanyNavigate()

  useEffect(() => {
    if (companyId === 'cn') {
      setCompanyLogo(chapterNextLogo)
      return
    }
    if (companyId === 'oc') {
      setCompanyLogo(oneCareerLogo)
      return
    }

    navigate('/oc/login')
    
  }, [companyId]);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { token } = await login(email, password, companyId!);
      localStorage.setItem('accessToken', token);

      const lastPathInfoString = localStorage.getItem('lastPath');
      if (lastPathInfoString) {
        try {
          const lastPathInfo = JSON.parse(lastPathInfoString);
          const currentTime = new Date().getTime();
          const timeElapsed = currentTime - lastPathInfo.timestamp;

          if (timeElapsed <= 300000) {
            companyNavigate(lastPathInfo.path);
            return;
          }
        } catch (parseError) { }
      }

      navigate('/');
    } catch (error: any) {
      showToast('Fehler beim Login', true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <section className="login-content">
        <Link
          to="/"
          className="navbar-brand d-flex align-items-center p-4 position-absolute"
        >
          <FontAwesomeIcon style={{ color: '#212529' }} icon={faDatabase} />
          <h4 className="logo-title ms-3 mb-0">{appName}</h4>
        </Link>

        <Row className="m-0 align-items-center vh-100">
          <Col md="12">
            <Row className="justify-content-center">
              <Col md="6">
                <Card className="card shadow-none d-flex justify-content-center mb-0 auth-card">
                  <Card.Body>

                    {companyLogo &&     <img
                      src={companyLogo}
                      alt="Company logo"
                    />}
                
                    <h2 className="mb-2 text-center">Anmelden</h2>
                    <Form onSubmit={handleLogin}>
                      <Row>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email" className="">
                              E-Mail Adresse
                            </Form.Label>
                            <Form.Control
                              isInvalid={!!error}
                              required
                              type="email"
                              className=""
                              id="email"
                              aria-describedby="email"
                              placeholder="xyz@beispiel.de"
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg="12" className="">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="password" className="">
                              Passwort
                            </Form.Label>
                            <Form.Control
                              isInvalid={!!error}
                              required
                              type="password"
                              className=""
                              id="password"
                              aria-describedby="password"
                              placeholder="Passwort"
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {error}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg="12" className="d-flex justify-content-end">
                          <Link to="/default/auth/recoverpw">
                            Passwort vergessen?
                          </Link>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-center">
                        <Button
                          type="submit"
                          variant="btn btn-primary"
                          disabled={
                            !email.trim() || !password.trim() || isLoading
                          }
                        >
                          Anmelden
                          {isLoading && (
                            <Spinner
                              className="ms-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            ></Spinner>
                          )}
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

SignIn.displayName = 'SignIn';
export default SignIn;
