import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { Focus, } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { useParams } from 'react-router-dom';
import { SelectOption } from '../../form/SelectWithSearch';
import DeleteModal from '../../DeleteModal';
import useCompanyNavigate from '../../../utils/hooks/useCompanyNavigate';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { PermissionsEnum } from '../../../utils/enum';
import Card from '../../bootstrap/card';
import { handleInputChange } from '../../../utils/form/utils';

type AddEditFocusModalProps = {
    modalTitle: string;
    focus?: Focus;
    onSubmitSuccess: (focusId: number) => void;
    onModalClose: () => void;
};

interface FormValues {
    title: string;
}

const AddEditFocusModal: React.FC<AddEditFocusModalProps> = ({
    modalTitle,
    focus,
    onSubmitSuccess,
    onModalClose
}) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const { focusId } = useParams<{ focusId?: string }>();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const { userHasPermissionByRight } = usePermissions();
    const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
    const [formValues, setFormValues] = useState({
        title: focus?.title ?? '',
    });

    const companyNavigate = useCompanyNavigate()

    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                let response;
                if (focusId) {
                    response = await ApiClient.put(
                        `/focuses/${focusId}`,
                        formValues
                    );
                } else {
                    response = await ApiClient.post('/focuses', formValues);
                }
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    const createFormGroup = (
        id: keyof FormValues,
        label: string,
        type = 'text',
        required = false,
        infoLabel: string = '',
    ) => {

        const formatDate = (dateStr: string = "") => {
            if (type === 'date') {
                return dateStr.split(' ')[0];
            }
            return dateStr;
        };

        return (
            <Form.Group className="mb-3 w-100" controlId={id.toString()}>
                <Form.Label style={{ color: 'black' }}>{label} {infoLabel && <small>{infoLabel}</small>}</Form.Label>
                <Form.Control
                    style={{ backgroundColor: '#F9F9F9', color: 'black' }}
                    type={type}
                    value={type !== 'file' ? (type === 'date' ? formatDate(formValues[id]?.toString()) : formValues[id]) : ''}
                    onChange={(e) => handleInputChange(e, setFormValues)}
                    required={required}
                    isInvalid={validated && !formValues[id]}
                />
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            </Form.Group>
        );
    };

    const createSelectGroup = (
        id: keyof FormValues,
        label: string,
        options: SelectOption[],
        placeholder?: string,
        required = false
    ) => (
        <Form.Group
            style={{ color: 'black' }}
            className="mb-3 w-100"
            controlId={id}
        >
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Select
                value={formValues[id]}
                onChange={(e) => handleInputChange(e, setFormValues)}
                required={required}
                style={{ backgroundColor: '#F9F9F9', color: 'black' }}
            >
                {placeholder && <option value="">{placeholder}</option>}
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );

    const handleDeleteItem = (message: string, isError: boolean) => {
        showToast(message, isError)
        onModalClose()
        companyNavigate('/keywords')
    }

    return (
        <>
            <Modal
                centered
                show
                onHide={onModalClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            {' '}
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button"
                                style={{ padding: 0 }}
                            >
                                <span className="visually-hidden">More options</span>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                                {focus && <DeleteModal
                                    modalTitle="Löschen?"
                                    buttonName="Löschen"
                                    entityIds={focus?.id}
                                    entityType='focuses'
                                    onSubmit={handleDeleteItem}
                                    onDeleteModalChange={setIsTopLevelModalOpen}
                                    isDropdownItem={true}
                                    isDisabled={!userHasPermissionByRight(PermissionsEnum.Focuses, 'delete')}
                                >
                                </DeleteModal>}

                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <>
                            <Row>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            {createFormGroup('title', 'Titel', 'text', true)}
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card className='card-block card-stretch card-height'>
                                        <Card.Body>
                                            {createFormGroup('title', 'Titel', 'text', true)}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default AddEditFocusModal;
