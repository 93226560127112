import React, { useState } from 'react';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { AccountId, FieldConfig } from '../../interfaces';
import DeleteModal from '../DeleteModal';
import ArchiveModal from '../ArchiveModal';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { PermissionsEnum } from '../../utils/enum';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownloadZipModal from '../DownloadZipModal';
import PortalWrapper from '../PortalWrapper';
import BulkEditModal from '../BulkEditModal';

interface SelectionActionsProps {
  selectedCount: number;
  selectedLocations: any;
  handleDeSelectAll: () => void;
  handleSelectAll: () => void;
  onSubmitSuccess: (message?: string, isError?: boolean) => void;
  accounts?: AccountId
  publicationStatus?: number | null
  amountAllItems: number,
  fieldConfigs: FieldConfig[]
  queryParams: string
}

const SelectionActions: React.FC<SelectionActionsProps> = ({
  selectedCount,
  selectedLocations,
  handleDeSelectAll,
  onSubmitSuccess,
  handleSelectAll,
  accounts,
  publicationStatus,
  amountAllItems,
  fieldConfigs,
  queryParams
}) => {
  const { userHasPermissionByRight } = usePermissions();
  const [isAllSelected, setIsAllSelected] = useState(amountAllItems === selectedCount)
  console.log(publicationStatus)
  return (
    <Row
      className="d-flex justify-content-between p-3 mx-1 mb-2 bg-light-blue"
    >
      <Col className="d-flex align-items-center">
        <span>{isAllSelected ? amountAllItems : selectedCount} Elemente wurden ausgewählt</span>
        <Button variant="link" onClick={handleDeSelectAll}>
          Auswahl aufheben
        </Button>
        {
          !isAllSelected && (
            <Button
              variant="link"
              className="ps-0"
              onClick={() => {
                setIsAllSelected(true);
                handleSelectAll();
              }}
            >
              Alle Auswählen ({amountAllItems})
            </Button>
          )
        }

      </Col>
      <Col className="d-flex justify-content-md-end">
        <Dropdown className="me-2">
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-basic"
            className="btn-soft-primary dropdown-no-arrow round-button"
          >
            <FontAwesomeIcon className='text-black' size='lg' icon={faEllipsisVertical} />
          </Dropdown.Toggle>
          <PortalWrapper>
            <Dropdown.Menu>
              <BulkEditModal
                modalTitle={`${isAllSelected ? amountAllItems : selectedCount} Standorte bearbeiten`}
                buttonName="Ausgewählte Standorte bearbeiten"
                entityIds={isAllSelected ? [] : Object.keys(selectedLocations)
                  .filter(key => selectedLocations[key] === true)
                  .map(Number)}
                onSubmitSuccess={onSubmitSuccess}
                isDisabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'write')}
                entityType='locations'
                isDropdownItem
                fieldConfigs={fieldConfigs}
                queryParams={queryParams}
              ></BulkEditModal>

              <DownloadZipModal
                modalTitle={`${isAllSelected ? amountAllItems : selectedCount} Zertifizierungsdaten herunterladen`}
                buttonName="Zertifizierungsdaten erstellen"
                entityIds={isAllSelected ? [] : Object.keys(selectedLocations)
                  .filter(key => selectedLocations[key] === true)
                  .map(Number)}
                isDisabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'write')}
                isDropdownItem
                endpoint={'/locations/certification-zip'}
                onSubmit={onSubmitSuccess}
                queryParams={queryParams}
              ></DownloadZipModal>

              

              {Number(publicationStatus) === 3 && <DeleteModal
                modalTitle="Auswahl löschen?"
                buttonName="Löschen"
                entityType='locations'
                entityIds={isAllSelected ? [] : Object.keys(selectedLocations)
                  .filter(key => selectedLocations[key] === true)
                  .map(Number)}
                onSubmit={onSubmitSuccess}
                isDisabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'delete')}
                isDropdownItem
                queryParams={queryParams}
              >
              </DeleteModal>}

              {Number(publicationStatus) !== 3 && <ArchiveModal
                modalTitle="Auswahl archivieren?"
                buttonName="Archivieren"
                entityType='locations'
                queryParams={queryParams}
                entityIds={isAllSelected ? [] : Object.keys(selectedLocations)
                  .filter(key => selectedLocations[key] === true)
                  .map(Number)}
                onSubmit={onSubmitSuccess}
                isDisabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'delete')}
                isDropdownItem>
              </ArchiveModal>}

            </Dropdown.Menu>
          </PortalWrapper>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default SelectionActions;
