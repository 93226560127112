import {
    memo,
    Fragment,
    useState,
    useEffect,
} from 'react';
import { Row, Col, Table, Dropdown, Button } from 'react-bootstrap';
import { PermissionsEnum } from '../../utils/enum';
import Card from '../../components/bootstrap/card';
import { useSortableData } from '../../utils/hooks/useSortableData';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';
import { MediaItem } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import UploadMediaModal from '../../components/media/modal/UploadMediaModal';
import MediaDetails from '../../components/media/MediaDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faDownload, faPen, faInfoCircle, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import NewFolderModal from '../../components/media/modal/NewFolderModal';
import { formatSizeUnits, getMediaIcon, getMediaStatusIcons, getMediaUrl } from '../../utils/media/utils';
import Breadcrumbs from '../../components/media/Breadcrumbs';
import MediumEditModal from '../../components/media/modal/MediumEditModal';
import PortalWrapper from '../../components/PortalWrapper';
import DeleteModal from '../../components/DeleteModal';
import { useNavigate } from 'react-router-dom';

interface EmbeddedMediaProps {
    initialFolderId?: number | null
}

interface MediaResponse {
    page: number;
    itemsPerPage: number;
    amountPages: number;
    amountAllItems: number;
    list: MediaItem[];
}

const EmbeddedMedia: React.FC<EmbeddedMediaProps> = memo(({ initialFolderId = 0 }) => {
    const [selectedFolderId, setSelectedFolderId] = useState(initialFolderId);
    const { show, message, error, showToast, hideToast } = useToast();
    const [media, setMedia] = useState<MediaItem[]>([]);
    const [selectedMediaForDetailView, setSelectedMediaForDetailView] = useState<MediaItem | null>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(20);
    const [limit, setLimit] = useState<number>(25);
    const [totalEntries, setTotalEntries] = useState<number>(200);
    const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
    const { items: sortedMedia, requestSort } = useSortableData(media);
    const [breadcrumbs, setBreadcrumbs] = useState<MediaItem[]>([]);
    const [dropdownSelectedMedia, setDropdownSelectedMedia] = useState<MediaItem | null>();
    const [showMediumEditModal, setShowMediumEditModal] = useState(false);
    const navigate = useNavigate();

    const handleBreadcrumbs = (newBreadcrumbs: MediaItem[]) => {
        setBreadcrumbs(newBreadcrumbs);
    };

    const fetchMedia = async () => {
        setMedia([]);
        setIsLoading(true);
        let queryParams = `?page=${currentPage}&folder_id=${Number(selectedFolderId) === 0 ? 'IS%20NULL' : selectedFolderId}`;

        if (limit.toString()) {
            queryParams += `&limit=${limit}`;
        }

        try {
            const response = await ApiClient.get(`/media${queryParams}`);
            const mediaResponse = response.data as MediaResponse;
            setTotalPages(mediaResponse.amountPages);
            setMedia(mediaResponse.list ?? []);
            setCurrentPage(mediaResponse.page);
            setLimit(mediaResponse.itemsPerPage);
            setTotalEntries(mediaResponse.amountAllItems);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        if (permissionsLoaded) {
            const hasPermission = userHasPermissionByRight(PermissionsEnum.Media, 'read');
            if (hasPermission) {
                fetchMedia();
            } else {
                showToast('Insufficient permissions', true);
            }
        }
    }, [permissionsLoaded, currentPage, selectedFolderId, limit]);

    const handleNewFolderSubmit = (mediaId: number | null, message?: string, isError?: boolean) => {
        if (message) {
            showToast(message, isError);
        }
        fetchMedia();
    };

    const handleMediaUpdateSubmit = (message?: string, isError?: boolean) => {
        if (message) {
            showToast(message, isError);
        }
        fetchMedia();
    };

    const handleMediumOrFolderClick = (media: MediaItem) => {
        if (media.mediaType === 'inode') {
            setSelectedMediaForDetailView(null);
            setCurrentPage(1);
            setSelectedFolderId(media.id);
        } else {
            setSelectedMediaForDetailView(media);
        }
    };

    return (
        <Fragment>
            <Row>
                <Col md={selectedMediaForDetailView ? 9 : 12}>
                    <Card className="card-block card-stretch">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
                                <Breadcrumbs isMediaPicker onBreadcrumbsClick={(id) => setSelectedFolderId(id)} onBreadcrumbsChange={handleBreadcrumbs} currentFolderId={selectedFolderId} />
                                <div className="d-flex">
                                    <NewFolderModal
                                        selectedFolderId={selectedFolderId}
                                        modalTitle="Neuer Ordner"
                                        buttonName="Neuer Ordner"
                                        onSubmitSuccess={handleNewFolderSubmit}
                                        onFolderModalChange={() => { }}
                                        isDisabled={!userHasPermissionByRight(PermissionsEnum.Media, 'write')}>
                                    </NewFolderModal>

                                    <Dropdown>
                                        <Dropdown.Toggle
                                            variant="secondary"
                                            id="dropdown-basic"
                                            className="btn btn-soft-primary"
                                        >
                                            Hinzufügen
                                        </Dropdown.Toggle>
                                        <PortalWrapper>
                                            <Dropdown.Menu>
                                                <UploadMediaModal
                                                    accept={{
                                                        'image/*': ['.jpg', '.jpeg', '.png', '.gif'], // Accept only image files
                                                    }}
                                                    isDropdownItem
                                                    convention="locations-image"
                                                    selectedFolderId={selectedFolderId}
                                                    modalTitle="Upload Bild"
                                                    buttonName="Upload Bild"
                                                    onSubmitSuccess={handleMediaUpdateSubmit}
                                                    onUploadMediaModalChange={() => { }}
                                                    isDisabled={!userHasPermissionByRight(PermissionsEnum.Media, 'write')}
                                                ></UploadMediaModal>

                                                <UploadMediaModal
                                                    accept={{
                                                        'application/pdf': ['.pdf'], // Accept only PDF files
                                                    }}
                                                    isDropdownItem
                                                    convention="locations-contract"
                                                    selectedFolderId={selectedFolderId}
                                                    modalTitle="Upload Dokument"
                                                    buttonName="Upload Dokument"
                                                    onSubmitSuccess={handleMediaUpdateSubmit}
                                                    onUploadMediaModalChange={() => { }}
                                                    isDisabled={!userHasPermissionByRight(PermissionsEnum.Media, 'write')}
                                                ></UploadMediaModal>
                                            </Dropdown.Menu>
                                        </PortalWrapper>
                                    </Dropdown>


                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <div style={{ overflowX: 'auto' }}>
                        <Table responsive="md" size="sm">
                            <thead>
                                <tr>
                                    <th className="d-flex align-items-center" onClick={() => requestSort('title')}>
                                        Name
                                    </th>
                                    <th style={{ cursor: 'pointer', width: '80px' }} onClick={() => requestSort('size')}>Dateigröße</th>
                                    <th style={{ cursor: 'pointer', width: '80px' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    Array.from({ length: 8 }).map((_, index) => (
                                        <SkeletonRow key={index} columnCount={3} />
                                    ))
                                ) : (
                                    sortedMedia.map((media) => (
                                        <tr key={media.id} className={`${selectedMediaForDetailView?.id === media.id ? 'bg-light-blue' : 'bg-white'} cursor-pointer text-black`}>
                                            <td onClick={() => handleMediumOrFolderClick(media)}>
                                                {getMediaIcon(media)} {getMediaStatusIcons(media)} {media.title}
                                            </td>
                                            <td className='text-center'>{media.size ? formatSizeUnits(media.size) : '-'}</td>

                                            <td className='d-flex'>
                                                <Button
                                                    onClick={() => window.open(`/${sessionStorage.getItem('companyId') || 'oc'}/media/${selectedFolderId}/${media.id}`, '_blank')}
                                                    as="span"
                                                    bsPrefix="btn"
                                                    className="no-border bg-transparent"
                                                >
                                                    <FontAwesomeIcon className="text-muted" icon={faPenToSquare} />
                                                </Button>

                                                <Dropdown>
                                                    <Dropdown.Toggle as="span"
                                                        bsPrefix="btn"
                                                        className="no-border">
                                                        <FontAwesomeIcon icon={faEllipsisVertical} />
                                                    </Dropdown.Toggle>
                                                    <PortalWrapper>
                                                        <Dropdown.Menu>
                                                            {media.mediaType !== 'inode' && (
                                                                <a href={getMediaUrl(media)} target="_blank" rel="noopener noreferrer">
                                                                    <Dropdown.Item as="div">
                                                                        <FontAwesomeIcon width={30} icon={faDownload} />Download
                                                                    </Dropdown.Item>
                                                                </a>
                                                            )}
                                                            <Dropdown.Item onClick={() => {
                                                                setDropdownSelectedMedia(media);
                                                                setShowMediumEditModal(true)
                                                            }}>
                                                                <FontAwesomeIcon width={30} icon={media.locked ? faInfoCircle : faPen} />
                                                                {media.locked ? 'Info' : 'Edit'}
                                                            </Dropdown.Item>
                                                            <DeleteModal
                                                                modalTitle="Delete?"
                                                                buttonName="Delete"
                                                                entityIds={media.id}
                                                                entityType="media"
                                                                onSubmit={handleMediaUpdateSubmit}
                                                                isDropdownItem
                                                                isDisabled={!userHasPermissionByRight(PermissionsEnum.Media, 'delete')}
                                                            />
                                                        </Dropdown.Menu>
                                                    </PortalWrapper>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                        {!isLoading && sortedMedia.length === 0 && (
                            <div
                                className="d-flex justify-content-center align-items-center border rounded my-3"
                                style={{ height: '50px' }}
                            >
                                <p className="p-0 m-0">Keine Medien in Ordner <b>{breadcrumbs[breadcrumbs.length - 1].title}</b> gefunden</p>
                            </div>
                        )}
                    </div>

                    {totalEntries > 0 && (
                        <Row>
                            <Col>
                                <PaginationInfo
                                    currentPage={currentPage}
                                    limit={limit}
                                    totalEntries={totalEntries}
                                    onLimitChange={(size) => {
                                        setLimit(size);
                                        setCurrentPage(1);
                                    }}
                                />
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <DynamicPagination
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                    )}
                </Col>
                <Col md={selectedMediaForDetailView ? 3 : 0}>
                    {selectedMediaForDetailView && <MediaDetails firstColumnSize={6} media={selectedMediaForDetailView} onClose={() => setSelectedMediaForDetailView(null)} />}
                </Col>
            </Row>

            <NotificationToast show={show} onClose={hideToast} message={message} error={error} />

            {showMediumEditModal && dropdownSelectedMedia && <MediumEditModal
                modalTitle={`${dropdownSelectedMedia.mediaType === 'inode' ? 'Ordner' : "Datei"} bearbeiten`}
                media={dropdownSelectedMedia}
                onSubmit={handleMediaUpdateSubmit}
                onModalClose={() => {
                    setDropdownSelectedMedia(null);
                    setShowMediumEditModal(false)
                }}
            >
            </MediumEditModal>
            }
        </Fragment>
    );
});

export default EmbeddedMedia;
