import React from 'react';
import { Modal, ListGroup, Button } from 'react-bootstrap';
import { MediaItem } from '../interfaces';
import { getMediaUrl } from '../utils/media/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faX, faUpload } from '@fortawesome/pro-regular-svg-icons';

interface MediaViewerModalProps {
    show: boolean;
    handleClose: () => void;
    media: MediaItem[];
    currentMediaIndex: number;
    setCurrentMediaIndex: (index: number) => void;
    title?: React.ReactNode | string
}

/**
 * MediaViewerModal Component
 * 
 * This component provides a full-screen modal to view and navigate through a list of media items.
 * It displays a list of media on the left side, allowing users to select an item to view its preview on the right.
 * The modal supports displaying images, PDFs, and a fallback message for unsupported media types.
 * 
 * Props:
 * - `show`: Controls whether the modal is visible.
 * - `handleClose`: Function to close the modal.
 * - `media`: Array of `MediaItem` objects containing information about each media item.
 * - `currentMediaIndex`: Index of the currently selected media item.
 * - `setCurrentMediaIndex`: Function to set the selected media item index, enabling navigation within the modal.
 * - `title`: Optional title displayed in the modal header.
 * 
 * Functionalities:
 * - Displays the media title and type.
 * - Allows downloading and opening media in a new tab.
 * - Provides a list view of media on the left for easy navigation, with the selected item highlighted.
 * - Shows a preview of the current media on the right, with support for images and PDFs.
 * - Displays a fallback message for unsupported media types.
 * 
 * Example usage:
 * ```tsx
 * <MediaViewerModal
 *   show={true}
 *   handleClose={() => setModalShow(false)}
 *   media={mediaItems}
 *   currentMediaIndex={0}
 *   setCurrentMediaIndex={setCurrentMediaIndex}
 *   title="Media Viewer"
 * />
 * ```
 */
const MediaViewerModal: React.FC<MediaViewerModalProps> = ({
    show,
    handleClose,
    media,
    currentMediaIndex,
    setCurrentMediaIndex,
    title
}) => {
    const currentMedia = media[currentMediaIndex];

    if (!currentMedia) {
        return null
    }

    return (
        <Modal show={show} onHide={handleClose} fullscreen>
            <Modal.Header className="d-flex justify-content-between align-items-center">
                <div className='d-flex'>
                    <Button
                        variant="secondary"
                        className="btn-soft-primary round-modal-close-button me-2"
                        style={{ padding: 0 }}
                        onClick={handleClose}
                    >
                        <FontAwesomeIcon icon={faX} size='sm' />
                    </Button>

                    <Modal.Title>{title}</Modal.Title>
                </div>
                <div>


                    <a href={`/${sessionStorage.getItem('companyId') || 'oc'}/media/${currentMedia.folder_id}/${currentMedia.id}`} target="_blank" rel="noopener noreferrer">
                        <Button
                            variant="secondary"
                            className="btn-soft-primary me-2"
                        >
                            Zum Medium <FontAwesomeIcon icon={faUpload} size='sm' />
                        </Button>
                    </a>


                    <a href={getMediaUrl(currentMedia)} target="_blank" rel="noopener noreferrer">
                        <Button
                            variant="secondary"
                            className="btn-soft-primary me-2"
                        >
                            Herunterladen <FontAwesomeIcon icon={faDownload} size='sm' />
                        </Button>
                    </a>

                </div>
            </Modal.Header>
            <Modal.Body className="d-flex py-0">
                <div className="media-list me-3" style={{ width: '300px', overflowY: 'auto', maxHeight: '100%' }}>
                    <ListGroup>
                        {media.map((item, index) => (
                            <ListGroup.Item
                                className='py-3 no-border rounded'
                                key={item.id}
                                active={index === currentMediaIndex}
                                action
                                onClick={() => setCurrentMediaIndex(index)}
                            >
                                {item.title}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
                <div className="media-preview flex-grow-1 d-flex justify-content-center align-items-center bg-grey" style={{ height: '100%' }}>
                    {currentMedia.mediaType === 'image' && (
                        <img src={getMediaUrl(currentMedia)} alt={currentMedia.title} className="img-fluid center-image" />
                    )}
                    {currentMedia.extension === 'pdf' && (
                        <iframe
                            src={getMediaUrl(currentMedia)}
                            title={currentMedia.title}
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                        ></iframe>
                    )}

                    {(currentMedia.mediaType !== 'image' && currentMedia.extension !== 'pdf') && (
                        <div className="text-center">
                            <h4>Leider steht für dieses Medium keine Vorschau zu Verfügung</h4>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MediaViewerModal;
