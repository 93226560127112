import axios from 'axios';

async function login(email: string, password: string, companyId: string) {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/${companyId}/auth/login`,
    {
      email,
      password,
    }
  );
  return response.data;
}

export { login };
