import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import useCompanyNavigate from '../utils/hooks/useCompanyNavigate';

interface AccountData {
    name: string;
    amount: number;
    amountLabel: string;
    accountId: number
}

interface BarChartProps {
    accounts: AccountData[];
}

const BarChart: React.FC<BarChartProps> = ({ accounts }) => {
    const companyNavigate = useCompanyNavigate()

    const options: ApexOptions = {
        chart: {
            type: 'bar',
            height: 350,
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    const accountIndex = config.dataPointIndex;
                    const account = accounts[accountIndex];
                    if (account && account.accountId) {
                        companyNavigate(`/locations?searchTerm=${account.accountId}&searchColumn=locations.accountId`)
                    }
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: '12px',
            },
        },
        xaxis: {
            categories: accounts.map(account => account.name),
            labels: {
                style: {},
            },
        },
        yaxis: {
            max: 60000,
            labels: {
                style: {},
            },
        },
        colors: ['#2e45b9'],
        title: {
            style: {
                fontSize: '20px',
            },
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (val: number, { dataPointIndex }: { dataPointIndex: number }) => {
                    return accounts[dataPointIndex].amountLabel;
                },
            },
        },
        annotations: {
            yaxis: [
                {
                    y: 50000,
                    borderColor: '#FF0000',
                    borderWidth: 2,
                    label: {
                        text: 'Obergrenze Angebote pro Account',
                        style: {
                            color: '#FF0000',
                            background: '#FFF',
                        }
                    }
                }
            ]
        },
    };

    const series = [
        {
            name: 'Angebote',
            data: accounts.map(account => account.amount),
        },
    ];

    return <Chart options={options} series={series} type="bar" height={350} />;
};

export default BarChart;
